<template>
    <div class="refund_wrap">
        <div class="refund">
            <div class="refund_title">
                Litely
            </div>
            <div class="refund_money_back">
                100% money-back guarantee
            </div>
            <div class="refund_content_title margin_wrap">
                1. Stay consistent with your fasting plan.
            </div>
            <div class="refund_content_describe">
                Stick to your personal plan, including your fasting schedule, hydration goal, and exercise routine.
            </div>
            <div class="refund_content_title">
                2. Track your progress.
            </div>
            <div class="refund_content_describe">
                Track your fasts, workouts, and water intake with the tracking tools on the app.
            </div>
            <div class="refund_content_title">
                3. Log your weight once a week.
            </div>
            <div class="refund_content_describe">
                So you can have a better idea of your weight progress.
            </div>


            <div class="refund_content_describe margin_wrap">
                It takes a minimum of 21 days to develop a new habit, and we stand behind our claim! If, after using our
                product as directed for three months, you don't experience any physical or mental health benefits, we'll
                refund your money.
            </div>

            <div class="refund_content_describe margin_wrap">
                To ensure success, strive for balance and find an approach that suits you by taking into account the
                following notes as well:
            </div>

            <div class="refund_content_describe margin_wrap">
                <div> ✔️ Adjust your fasting schedule to fit your lifestyle. </div>
                <div> ✔️ Listen to your body and make adjustments as necessary. </div>
                <div> ✔️ Take occasional breaks from intermittent fasting. </div>
            </div>

            <div class="refund_content_describe margin_wrap">
                Litely has already helped millions of people achieve their weight loss goals, and we're excited for you to
                experience the benefits of intermittent fasting with us - losing weight, improving your sleep, boosting your
                mental clarity, increasing your energy
                levels, and restoring balance to your life. We can't wait to help you take control of your health and
                achieve the results you desire.
            </div>

            <div class="refund_money_back margin_wrap">Let's get started! 💪🏼</div>
            <a class="refund_button margin_wrap" href="https://forms.gle/orMDC5XVqKMhBLiDA">Get in Touch Here</a>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.$store.commit("setIsApp", true);
    },
    methods: {}
}
</script>

<style scoped lang="less">
.refund_wrap {
    background: linear-gradient(180deg, #FFF2F2 0%, #FFF5F1 100%);
    min-height: 100vh;

    .refund {
        max-width: 765px;
        padding: 88px 24px 74px;
        text-align: left;
        margin: 0 auto;

        .refund_title {
            font-size: 40px;
            font-family: 'AvenirNext';
            font-weight: 700;
            color: #444444;
            line-height: 55px;
        }

        .refund_money_back {
            font-size: 30px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #444444;
            line-height: 41px;
            margin-top: 24px;
        }

        .refund_content_title {
            font-size: 22px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: rgba(51, 51, 51, 0.8);
            line-height: 30px;
            margin-top: 20px;
        }

        .refund_content_describe {
            font-size: 18px;
            font-family: 'AvenirNext';
            font-weight: 500;
            color: rgba(51, 51, 51, 0.7);
            line-height: 25px;
            margin-top: 10px;
        }

        .margin_wrap {
            margin-top: 40px;
        }

        .refund_button {
            font-size: 18px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #FFFFFF;
            line-height: 24px;
            width: 288px;
            height: 50px;
            background: #FD7C61;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>